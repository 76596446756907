.blue-play-button {
   background-color: #3386cc !important; }

.favorite-slide {
   position: absolute;
   right: 5px;
   bottom: 60px;
   height: 49px;
   width: 33px;
   z-index: 500;

   .favorite-num {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-tap-highlight-color: transparent;
      border-radius: 100px;
      display: inline-flex;
      height: 19px;
      line-height: 1;
      font-weight: 500;
      font-size: .7125rem;
      min-width: 19px;
      -webkit-user-select: none;
      background-color: transparent;
      cursor: pointer;

      span {
         justify-content: center;
         display: inline-flex;
         color: white;
         width: 100%;
         padding: 0;
         height: 100%;
         align-items: center;
         line-height: 1; } } }



.bi-bookmark-fill {
   cursor: pointer;
   font-size: 33px;
   -webkit-text-stroke-width: 0.3px;
   -webkit-text-stroke-color: #000000;

   &.not-favorite-icon {
      -webkit-text-fill-color: #808080c2; }

   &.favorite-icon {
      -webkit-text-fill-color: #FF8C00; } }
.slide-image {
   width: 100%;
   height: auto;
   object-fit: contain;
   aspect-ratio: 16 / 9;
   background-color: #000000; }
.slide-image-wrapper {
   background: black; }

.slide-free.carousel.slide {
   padding-bottom: 30px; }

.translated-img {
   width: 100%;
   background-color: #000000; }

.loading-translating-img {
   position: relative;

   .loading-icon {
      width: 50px;
      height: 50px;
      background-size: cover; } }

.video-slide-control {
   background-color: #F1F3F4;
   padding: 10px; }

.app-body[data-theme="dark"] .video-slide-control {
   background-color: #222222; }

.auto-download-slide {
   gap: 8px; }

.translate-slide {
   gap: 5px;

   select {
      width: 120px;
      height: 40px;
      padding: 8px;
      font-size: 14px;
      color: #666666;
      border-radius: 8px;
      background-color: #FFFFFF; } }

.btn-icon {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background-color: #3386CC;
   border: 1px solid #3386CC;

   img {
      width: 20px; }

   i {
      font-size: 16px;
      color: #FFFFFF; }

   .btn-slide-download {
      width: 24px; }
   .bi-play {
      font-size: 28px;
      transform: translateY(-4px);
      display: inline-block; } }

.btn-icon:disabled {
   opacity: .5; }

.remove-translated-img {
   position: absolute;
   top: 8px;
   right: 8px;
   z-index: 2;

   .btn-icon {
      width: 30px;
      height: 30px;
      background-color: #808080;
      border: 1px solid #808080;

      i {
         font-size: 24px;
         color: #000000;
         transform: translate(-4px, -6px);
         display: inline-block; } } }
